.edit_project {
    position: relative;

    &__header-image {
        width: 100%;
        height: 300px;
        position: relative;
        overflow: hidden;
    }

    &__crop-image {
        overflow: hidden;
        width: 100%;
        height: 250px;
        position: absolute;
        top: 25px;
    }

    &__image {
        width: 100%;
        margin-top: -120px;
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }

    &__image2 {
        width: 90%;
        margin: 0 5%;
    }

    &__texts {
        text-align: center;
    }
}

.image-edit-pop {
    background-color: rgba(36, 36, 36, 0.9);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: clamp(470px, 80vh, 1000px);
    z-index: 1000;

    &__edit-window {
        background-color: rgb(255, 255, 255);
        box-sizing: border-box;
        padding: 2%;
        width: clamp(250px, 60%, 1000px);
        height: clamp(250px, 70%, 900px);
        position: relative;
    }
}

.update_project {
    &__form-image {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 80%;
        margin: 1% auto;
    }
    &__crop-image {
        overflow: hidden;
        width: 90%;
        height: 300px;
        position: absolute;
        bottom: 2%;
    }
}

.edit-page-form {margin-top: 8%;}

.edit_btn {
    position: absolute;
    z-index: 1;
    margin: 2%;
}