@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import "./vars/vars";

body {
    font-family: "Rubik", sans-serif;
    margin: 0 auto;
    padding: 0;
}

.container {
    max-width: 1440px; 
    min-width: 320px;
    margin: 0 auto;
}

@import './auth/auth';
@import './dashboard/index';
