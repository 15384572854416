.feedback_main {
    @media screen and (max-width: 800px) {
        overflow-x: scroll;
    }

    &__feedbackList {
        position: relative;
        height: 60vh;
        min-width: 1100px;

        &--headers, &--list-item {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
        }

        &--headers,
        &--list {
            position: absolute;
        }

        &--headers {
            top: 0;
            width: 99%;
        }

        &--list {
            overflow-y: scroll;
            height: 86%;
            bottom: 0;

            &-item {
                width: 100%;

                margin: 1% auto;
                border-bottom: 1px solid rgb(144, 59, 255);
                border-top: 1px solid rgb(221, 162, 255);
                padding: 1% 0%;
                border-radius: 0.5rem;

                &:hover {
                    box-shadow: 0px 1px 2px 1px grey;
                }

                img {
                    width: 80%;
                }

                &--links {
                    display: flex;
                    justify-content: center;
                    width: 50%;
                }

                &--url {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                &--deleteBtn {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }
}