.add-project-page {
    text-align: center;
    &__inputs-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3%;

        &--description {
            grid-area: 2 / 1 / 3 / 3;
        }
    }
}