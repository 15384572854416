@import './nav';
@import './userCall';
@import './projectItem';
@import './editProject';
@import './addProject';
@import './projectList';
@import './addPost';
@import './feedback';
@import './blog';

.big_header {
    text-align: center;
    color: #8902ff;
}

.no_data_text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
}