.tiptop_edit {
    border: 1px solid rgb(103, 103, 103);
    background-color: white;
    // width: 60%;
    // margin: 3% auto;
    // border-radius: 10px;
}

.main_btns_edit {
    border-bottom: 1px solid rgb(157, 157, 157);
    background-color: rgb(245, 245, 245);

    & button {
        display: flex;
        font-size: 16px;
        justify-content: center;
        align-items: center;
    }
}

.flex_elements {
    display: flex;
    margin: 0.5%;
}

.edit_display {
    display: flex;
    flex-wrap: wrap;
}

.tiptap {
    height: 25vh;
    overflow-x: auto;
    box-sizing: border-box;
    padding: 0% 1%;
}

.font_color__palet {
    position: relative;

    &--elements {
        background-color: rgb(231, 230, 230);
        padding: 0.5ch;
        position: absolute;
        top: 30px;
        z-index: 100;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        // &-div {
        //     // margin: 5%;
        //     // width: 10vw;
        // }

        button,
        input {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        button {
            margin: 0.5ch;
        }

        input[type="color"]::-moz-color-swatch {
            border: none;
        }

        input[type="color"]::-webkit-color-swatch-wrapper {
            padding: 0;
            border-radius: 0;
        }

        input[type="color"]::-webkit-color-swatch {
            border: none;
        }
    }

    &--automatic-color,
    &--more-colors,
    &--unHightLight,
    &--more-hightlight {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;

        label {
            cursor: pointer;
            font-size: 13px;
        }
    }

    &--automatic-color,
    &--unHightLight {
        grid-area: 1 / 1 / 2 / 6;
        margin-bottom: 1ch;
        padding-bottom: 1ch;
        border-bottom: 1px solid rgb(183, 183, 183);
    }

    &--more-colors,
    &--more-hightlight {
        margin-top: 1ch;
        padding: 1ch 0;
        border-top: 1px solid rgb(181, 181, 181);
    }

    &--more-colors {
        grid-area: 3 / 1 / 4 / 6;
    }

    &--more-hightlight {
        grid-area: 4 / 1 / 5 / 6;

    }

    &--unHightLight {
        button {
            width: 100%;
        }
    }

    // &--unHightLight {
    //     button {
    //         // font-size: 13px;
    //     }

    // }
}

.table-edit {
    position: relative;

    // button {
    //     // width: 25%;
    // }

    img {
        width: 48px;
    }

    &__elements {
        background-color: rgb(231, 230, 230);
        padding: 0.5ch;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 30px;
        z-index: 100;

        &--add-edit,
        &--delete {
            display: flex;
        }
    }
}